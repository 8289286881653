<template>
	<div id="barra-brasil" style="background:#7F7F7F; height: 20px; padding:0 0 0 10px;display:block;">
			<ul id="menu-barra-temp" style="list-style:none;">
				<li style="display:inline; float:left;padding-right:10px; margin-right:10px; border-right:1px solid #EDEDED">
					<a href="http://brasil.gov.br" style="font-family:sans,sans-serif; text-decoration:none; color:white;">Portal do Governo Brasileiro</a>
				</li>
			</ul>
		</div>
</template>

<script>
export default {
	mounted() {
		let recaptchaScript = document.createElement('script')
		recaptchaScript.setAttribute('src', '//barra.brasil.gov.br/barra_2.0.js')
		document.head.appendChild(recaptchaScript)
	},
}
</script>
